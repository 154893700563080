import React from 'react';
import './App.css';
import Header from './Components/Header'
import Tagline from './Components/Tagline';
import CourseArea from './Components/CourseArea';
import ProfilePage from './Components/ProfilePage';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import WishlistPage from './Components/WishlistPage';
import CartPage from './Components/CartPage';
import DescriptionPage from './Components/DescriptionPage';

function App() {
  return (
    <Router>
    <div className="React-App">
      <Route exact path="/" render={
        ()=>{
          return(
            <>
            <header className="App-header">
              <Header />
            </header>
              <Tagline/>
              <CourseArea/>
            </>
          );}} />
    
     <Route path="/profile" render={
        ()=>{
          return(
            <>
              <ProfilePage/>
            </>
          );}} />

      <Route path="/wishlist" render={
        ()=>{
          return(
            <>
              <WishlistPage/>
            </>
          );}} />
        <Route path="/cart" render={
        ()=>{
          return(
            <>
              <CartPage/>
            </>
          );}} />
          <Route path="/description" render={
        ()=>{
          return(
            <>
              <DescriptionPage/>
            </>
          );}} />
      
      </div>   
    
    </Router>
  
  );
}

export default App;
