import React from 'react'
import CourseInCart from './CourseInCart';
import mock from  './mock.json'
const CoureSearchArea = () => {
    return (
        <div id="search-area">
            <div id="search-bar">
                <input id="search-here" placeholder="Search here" />
                <div id="search-icon"></div>
            </div>
            <div id="search-pannel">
                <div id="cart-heading">YOUR CART DETAILS</div>
                <div id="cart-material">
                  <CourseInCart info={mock.course_detail}/>
                  {/* <CourseInCart/>  
                  <CourseInCart/> 
                  <CourseInCart/> 
                  <CourseInCart/>  */}
                </div> 
                <div id="side-cart-footer">
                    <div id="total-cart-value">
                        <div id="total-text">Total Cart Value</div>
                        <div id="total-cart-price">Rs 2353/-</div>
                    </div>
                    <a id="a-style" href="/cart"><div id="checkout-button-cart">GO TO CHECKOUT
                    </div></a>
                        
                </div>
            </div>

            
        </div>
    )
}

export default CoureSearchArea
