import React from 'react'
import CartDisplay from './CartDisplay'
import TotalCartAmount from './TotalCartAmount'


const CartDesign = () => {
    return (
        <div id="course-area">
            <CartDisplay/>
            <TotalCartAmount/>
        </div>
    )
}

export default CartDesign
