import React from 'react'
import SingleCourse from './SingleCourse'
import mock from  './mock.json'
const CourseDisplay = () => {
    return (
        <div id="course">
            <div id="course-top-bar">
                <div id="course-top-bar-text">
                    All Courses
                </div>
                <div id="sorter">
                    
                    <select name="sort-price" id="price-sorter" >
                        <option selected disabled hidden> Course Price</option>
                        <option value="low-to-high">Low to High</option>
                        <option value="low-to-high">High to Low</option>
                
                    </select>
                </div>
            </div>
            <div>
                <SingleCourse info={mock.course_detail}/>
                {/* <SingleCourse/>
                <SingleCourse/>
                <SingleCourse/>
                <SingleCourse/>
                <SingleCourse/> */}
            </div>
            
        </div>
    )
}

export default CourseDisplay
