import React from 'react'

const WishlistCartArea = () => {
    return (
        <div id="search-area">
            <div id="search-bar">
               
            </div>
            <div id="search-pannel">
                <div id="cart-heading">YOUR CART DETAILS</div>
                <div id="cart-material">Your cart is empty right now. Please add courses in the cart from the list
            
                </div> 
                <div id="side-cart-footer">
                    <div id="total-cart-value">
                        <div id="total-text">Total Cart Value</div>
                        <div id="total-cart-price">Rs 0/-</div>
                    </div>
                 
                </div>
            </div>

            
        </div>
    )
}

export default WishlistCartArea
