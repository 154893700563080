import React from 'react'

const CourseInCart = (props:any) => {
    return (<div>
        <div className="card-class">
            <Print info={props.info}/>
        </div>
        
    </div>
)
}


function Print(props:any){
const info=props.info;
return(
    <div className="container">
        {info.map((info:any)=>(
            <>
            <PrintItems{...info}/>
            </>
        ))}
    </div>

);
}
function EmptyCart()
{
    return(
        <>
        </>
    )
}


function PrintItems(props:any)
{


if(props.cart=="0")
{
    return <EmptyCart/>
}

return(

<>
<div id="course-in-cart">
<div id="course-in-cart-title">
     <div id="course-in-cart-pic"></div>
        <div id="cart-course-name">{props.course_title}</div>
   
 </div>
<div id="course-cart-price">Rs {props.discount_price}/-</div>

</div>
</>
)}

       
export default CourseInCart


