import React from 'react'
import CoureSearchArea from './CoureSearchArea'
import CourseDisplay from './CourseDisplay'

const CourseArea = () => {
    return (
        <div id="course-area">
            <CourseDisplay/>
            <CoureSearchArea/>
        </div>
    )
}

export default CourseArea
