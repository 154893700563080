import React from 'react'

const ProfileDesign = () => {
    return (
        <>
        <div id="profile-area">
            <div id="profile-pic"></div>
            <div id="profile-container">
                    <div id="profile-names">
                            <div id="display-name" className="profile-text-design">Display Name
                            <input id="name-input" placeholder="Enter Name " type="text"/>
                            </div>
                            <div id="first-name" className="profile-text-design">First Name
                            <input id="name-input" placeholder="Enter Name " type="text"/>
                            </div>
                            <div id="last-name" className="profile-text-design">Last Name
                            <input id="name-input" placeholder="Enter Name " type="text"/>
                            </div>
                    </div>
                    <div id="about-yourself" className="profile-text-design">About Yourself
                            <input id="about-yourself-input"  type="textbox"/>
                    </div>
                    <div id="area-of-intrest" className="profile-text-design">Your Area of Interest
                           <div id="intrest-option" className="it-opn"> 
                            <input type="checkbox" id="checkbox-style"/>
                            <label>&nbsp;Designer</label>
                            </div>
                            <div id="intrest-option" >
                            <input type="checkbox" id="checkbox-style"/>
                            <label>&nbsp;Developer</label></div>
                            <div id="intrest-option">
                            <input type="checkbox" id="checkbox-style"/>
                            <label>&nbsp;Project Manager</label></div>
                            <div id="intrest-option">
                            <input type="checkbox" id="checkbox-style"/>
                            <label>&nbsp;Sales</label></div>
                    </div>
                    <div id="stud-or-prof" className="profile-text-design">Are you student or Professional
                            <div id="sp-opn">
                            <input type="radio" id="radio-style1" name="sp"/>
                                <label>&nbsp;Student</label>
                            </div>    
                            <div id="sp-opn">
                            <input type="radio" id="radio-style1" name="sp"/>
                                <label>&nbsp;Professional</label>
                            </div>
                    </div>
                    
                    <div id="more-info">
                        <div  className="profile-text-design">How much of experience you have? </div>
                        <div id="experience">
                                <div id="exp-opn">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;0-5</label>
                                </div>   
                                <div id="exp-opn">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;5-10</label>
                                        </div>   
                                <div id="exp-opn">
                                <input type="radio" id="radio-style2" name="exp"/>
                                        <label>&nbsp;10 n Above</label>
                                </div>       
                        </div>
                        <div className="profile-text-design">What is your expertise</div>
                            <div id="expertise" >  
                                <div id="ext-on">
                                <input type="radio" id="radio-style3" name="expt"/>
                                        <label>&nbsp;Java</label>
                                </div>
                                <div id="ext-on">
                                <input type="radio" id="radio-style3" name="expt"/>
                                        <label>&nbsp;React</label>
                                        </div>

                                <div id="ext-on">
                                <input type="radio" id="radio-style3" name="expt"/>
                                        <label>&nbsp;Backend</label>
                                 </div>
                            </div>
                        <div id="role" className="profile-text-design">Mention your role
                            <input id="role-input" placeholder="Type Your Role" type="text"/>
                        </div>
                    </div >
                    <div id="form-end">
                           SAVE
                    </div>
                      
                    </div>

            </div>
            </>
        
    )
}

export default ProfileDesign
