import React from 'react'
import mock from './mock.json'
const CartCourse = (props:any) => {
    return (
        <div>
            <div className="card-class">
                <Print info={props.info}/>
            </div>
            
        </div>
    )
}


    function Print(props:any){
        const info=props.info;
        return(
            <div className="container">
                {info.map((info:any)=>(
                    <>
                    <PrintItems{...info}/>
                    </>
                ))}
            </div>
    
    );
    }

    function CartEmpty()
    {   
        return(
            <>
            </>
        );
    }


    function PrintItems(props:any)
    {

        
        if(props.cart=="0")
        {
            return(<CartEmpty/>);}
    
    return(
    
    <>
    <div id="single-course"> 
    <div id="course-pic"></div>
    <div id="course-title">
        <div id="course-name">{props.course_title} </div>
        <div id="course-author">{props.author}</div>
    </div>
    <div id="move-to-wishlist">Move to Wishlist</div>
    <div id="original-price">Rs {props.discount_price}/-</div>
    <div id="course-delete"></div>
    </div>
    </>
    )}



export default CartCourse
