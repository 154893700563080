import React from 'react'

const CartTagline = () => {
    return (
        <div>
        <div id="tagline-background">
            <div id="tagline-text-profile">Shopping Cart </div>
        </div> 
        </div>
    )
}

export default CartTagline
