import React from 'react';
import Header from './Header'
import TaglineProfile from './TaglineProfile';
import ProfileDesign from './ProfileDesign';

const ProfilePage = () => {
    return (
    
    <div className="React-App">
      <header className="App-header">
        <Header />
      </header>
         <TaglineProfile/>
         <ProfileDesign/>
    </div>
        
    )
}

export default ProfilePage



