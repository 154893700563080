import React from 'react'
import mock from './mock.json'

const TotalCartAmount = () => {
    return (
        <div id="checkout-side-area">
            <div id="cart-text">Total Amount</div>
            <div id="cart-ta">Rs 2353/-</div>
            <div id="cart-sa">You have saved Rs 445/-</div>
            <div id="cart-btn">CHECKOUT</div>     
        </div>
    )
}

export default TotalCartAmount
