import React from 'react'
import Header from './Header'
import Tagline from './Tagline'
import WishlistDesign from './WishlistDesign'

const WishlistPage = () => {
    return (
        <div>
            <div className="React-App">
      <header className="App-header">
        <Header />
      </header>
         <Tagline/>
         <WishlistDesign/>
    </div>
        </div>
    )
}

export default WishlistPage
