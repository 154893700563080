import React from 'react'

const TaglineProfile = () => {
    return (
        <div>
        <div id="tagline-background">
            <div id="tagline-text-profile">My Profile </div>
        </div> 
        </div>
    )
}

export default TaglineProfile
