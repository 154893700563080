import React from 'react'

const SingleCourse = (props:any) => {
    return (
        <div>
            <div className="card-class">
                <Print info={props.info}/>
            </div>
            
        </div>
    )
}


function Print(props:any){
    const info=props.info;
    return(
        <div className="container">
            {info.map((info:any)=>(
                <>
                <PrintItems{...info}/>
                </>
            ))}
        </div>
   
   );
}



function PrintItems(props:any)
{

    let wish:string="course-wishlist";
    if(props.whishlist=="1")
    {wish="course-wishlist1";}
   
return(
   
<>
<div id="single-course"> 
<a href="/description"><div id="course-pic"></div></a>
 <div id="course-title">
     <div id="course-name">{props.course_title} </div>
     <div id="course-tags">
         <div id="tag-design">{props.tag1}</div>
         <div id="tag-design">{props.tag2}</div>
     </div>
 </div>
 <div id="course-author">{props.author}</div>
 <div id={wish}></div>
 <div id="original-price">Rs {props.discount_price}/-</div>
 <div id="discounted-price">Rs {props.original_price}/-</div>
 <div id="add-to-cart"><button id="button-addtocart">Add To Cart</button></div>
<div id="next-page">  </div>
 </div>
</>
)}


export default SingleCourse


