import React from 'react'
import mock from  './mock.json'
const WishlistCourse = (props:any) => {
    return (
        <div>
            <div className="card-class">
                <Print info={props.info}/>
            </div>
            
        </div>
    )
}


    function Print(props:any){
        const info=props.info;
        return(
            <div className="container">
                {info.map((info:any)=>(
                    <>
                    <PrintItems{...info}/>
                    </>
                ))}
            </div>
    
    );
    }

    function WishlistEmpty()
    {   
        return(
            <>
            </>
        );
    }


    function PrintItems(props:any)
    {

        
        if(props.whishlist=="0")
        {
            return(<WishlistEmpty/>);}
    
    return(
    
    <>
    <div id="single-course"> 
    <div id="course-pic"></div>
    <div id="course-title">
        <div id="course-name">{props.course_title} </div>
        <div id="course-tags">
            <div id="tag-design">{props.tag1}</div>
            <div id="tag-design">{props.tag2}</div>
        </div>
    </div>
    <div id="course-author">{props.author}</div>
    <div id="original-price">Rs {props.discount_price}/-</div>
    <div id="discounted-price">Rs {props.original_price}/-</div>
    <div id="add-to-cart"><button id="button-addtocart">Add To Cart</button></div>
    <div id="course-delete"></div>
    <div id="next-page">  </div>
    </div>
    </>
    )}


    export default WishlistCourse




















//         <div id="single-course"> 
//         <div id="course-pic"></div>
//         <div id="course-title">
//             <div id="course-name">Responsive Desgin Course XYZ How to design responsive templates  </div>
//             <div id="course-tags">
//                 <div id="tag-design">React</div>
//                 <div id="tag-design">React</div>
//             </div>
//         </div>
//         <div id="course-author">Joseph Marie</div>
//         <div id="original-price">Rs 563/-</div>
//         <div id="discounted-price">Rs 912/-</div>
//         <div id="add-to-cart"><button id="button-addtocart">Add To Cart</button></div>
//         <div id="course-delete"></div>
//         <div id="next-page">  </div>
//     </div>
//     )
// }

// export default WishlistCourse
