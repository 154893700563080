import React from 'react'

const Header = () => {
    return (
        
        <div id="top-bar">
            <a href="/"><div id="hashedin-logo"></div></a>
            <a href="/"><div id="courses-top-bar">COURSES</div></a>
            <a href="/wishlist"><div id="mywishlist-top-bar">MY WISHLIST</div></a>
            <a href="/cart"><div id="cart-img"></div></a>
            <a href="/profile"><div id="profile-icon"></div></a>
        </div>
        
    )
}

export default Header
