import React from 'react'

import DescriptionDesign from './DescriptionDesign'
import Header from './Header'
import Tagline from './Tagline'

const DescriptionPage = () => {
    return (
        <div className="React-App">
        <header className="App-header">
          <Header />
        </header>
           <Tagline/>
           <DescriptionDesign/>
      </div>
    )
}

export default DescriptionPage
