import React from 'react'

const DescriptionDesign = () => {
    return (
        <>
        <div className="course-design-head">
            <div id="design-top-bar">
                <div id="all-course">ALL COURSES</div>
                <div id="arrow-image"></div>
                <div id="sub-course">Responsive Design course</div>
            </div>
        </div>
        <div id="top-bar-div">
        <div id="course-descp">
            <div id="course-disc">Responsive Design Course XYZ How to design responsive templates</div>
            <div id="course-mini">Responsive Design Course XYZ How to design responsive templates</div>
            <div id="course-author2">Joseph Marie</div>
            <div id="tags-course">
                <button id="react-tag">React</button>
                <button id="react-tag">React</button>
                <button id="react-tag">React</button>
            </div>
        </div>
        <div >
        <iframe id="video" src="https://www.youtube.com/embed/watch?v=kuJNVKHHpGk&feature=youtu.be&ab_channel=HashedInUniversity"></iframe>
        </div>
        </div>
        <div>
        <div id="react-tagq">
            <div id="full-course-descp">
                <b>Course Details</b><br></br><br></br>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident ea eius nam nihil nesciunt veniam et rem consequuntur. <br></br><br></br>   
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident ea eius nam nihil nesciunt veniam et rem consequuntur. <br></br><br></br>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident ea eius nam nihil nesciunt veniam et rem consequuntur. <br></br><br></br>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident ea eius nam nihil nesciunt veniam et rem consequuntur. <br></br><br></br>
            </div>
            <div id="price-time">
                <div className="original-price">Rs 563/-</div>
                <div className="discounted-price">Rs 923/</div>
                <div id="time-remaining">
                    <div id="clock"></div>
                    <div className="time-left"><b>8 hours</b> left for this price</div>
                </div>
                <div id="cart-wishlist">
                    <div id="add-to-cart-design">ADD TO CART</div>
                    <div >
                        <div id="wishlisted">ADD TO WISHLIST</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    )
}

export default DescriptionDesign
