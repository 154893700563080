import React from 'react'
import Header from './Header'
import CartTagline from './CartTagline';
import CartDesign from './CartDesign';

const CartPage = () => {
    return (
        <div className="React-App">
        <header className="App-header">
          <Header />
        </header>
           <CartTagline/>
           <CartDesign/>
      </div>
          
    )
}

export default CartPage
