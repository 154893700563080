import React from 'react'
import CartCourse from './CartCourse';
import mock from './mock.json'
import RecommendedCourse from './RecommendedCourse';
const CartDisplay = () => {
    let count:number=0;
    for(let i:number=0 ;i<mock.course_detail.length;i++)
    {   let temp:string=mock.course_detail[i].cart; 
        if( temp=="1")
        {
            count++;
        }
    }
    return (
        
    <div id="course">
        <div id="course-top-bar">
            <div id="course-top-bar-text">
               {count} Courses in Cart
            </div>
        </div>
        <div>
            <CartCourse info={mock.course_detail}/> 
          
        </div>
        
        <div id="course1">
        <div id="course-top-bar">
            <div id="course-top-bar-text1">
               Recommendended Courses
            </div>
        </div>
        <div>
            <RecommendedCourse info={mock.course_detail}/> 
          
        </div>
        
        
    </div>
    </div>
    
   
    )
}

export default CartDisplay
