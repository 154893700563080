import React from 'react'

const Tagline = () => {
    return (
        <div id="tagline-background">
            <div id="tagline-text">Discover Latest Courses on </div>
            <div id="tagline-course">React</div>
        </div>
    )
}

export default Tagline
