import React from 'react'
import CoureSearchArea from './CoureSearchArea'
import CourseDisplay from './CourseDisplay'
import WishlistCartArea from './WishlistCartArea'
import WishlistDisplay from './WishlistDisplay'

const WishlistDesign = () => {
    return (
        <div id="course-area">
            <WishlistDisplay/>
            <WishlistCartArea/>
        </div>
    )
}

export default WishlistDesign
